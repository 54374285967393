import {createRouter, createWebHistory, RouteRecordRaw} from 'vue-router'

const routes: Array<RouteRecordRaw> = [

    // TODO - ADD ROUTES TO BACKEND (ViewController)
    {
        path: '/',
        redirect: (/*to*/) => {
            // the function receives the target route as the argument
            // we return a redirect path/location here.
            return { name: 'documentMain' }
        },
    },
    {
        path: '/login',
        name: 'login',
        meta: {
            module: 'login',
            title: 'Portal SNQTB-AFP | Login',
        },
        component: () => import(/* webpackChunkName: "login" */ '@/views/LoginPage.vue')
    },
    {
        path: '/password-recover/:code',
        name: 'recoverPassword',
        props: true,
        meta: {
            module: 'app'
        },
        component: () => import(/* webpackChunkName: "recoverpassword" */ '@/views/RecoverPassword.vue')
    },
    {
        path: '/users',
        name: 'usersList',
        meta: {
            title: 'Portal SNQTB-AFP | Utilizadores',
            module: 'admin'
        },
        component: () => import(/* webpackChunkName: "documentos" */ '@/views/admin/UsersList.vue'),
    },
    {
        path: '/area-utilizador',
        name: 'userArea',
        meta: {
            module: 'userArea'
        },
        component: () => import(/* webpackChunkName: "userarea" */ '@/views/MyUserArea.vue'),
    },
    {
        path: '/documentos',
        name: 'documentMain',
        meta: {
            title: 'Portal SNQTB-AFP | Pesquisar',
            module: 'documentos'
        },
        component: () => import(/* webpackChunkName: "documentos" */ '@/views/documents/DocumentsPanel.vue'),
    },
    {
        path: '/documentos/:id/documento',
        name: 'documentDetail',
        props: true,
        meta: {
            title: 'Portal SNQTB-AFP | Pesquisar',
            module: 'documentos'
        },
        component: () => import(/* webpackChunkName: "documentos" */ '@/views/documents/DocumentDetailPanel.vue'),
    },
    {
        path: '/det-rec',
        name: 'detRecMain',
        meta: {
            title: 'Portal SNQTB-ANF | Pesquisar',
            module: 'detrec'
        },
        component: () => import(/* webpackChunkName: "detrec" */ '@/views/detrec/DetRecPanel.vue')
    },
    {
        path: '/det-rec/:id/',
        name: 'detRecDetail',
        props: true,
        meta: {
            title: 'Portal SNQTB-ANF | Pesquisar',
            module: 'detrec'
        },
        component: () => import(/* webpackChunkName: "detrec" */ '@/views/detrec/DetRecDetailPanel.vue'),
    },
    {
        path: '/note-pdf',
        name: 'notePdfMain',
        meta: {
            title: 'Portal SNQTB-ANF | Pesquisar',
            module: 'notePdf'
        },
        component: () => import(/* webpackChunkName: "notepdf" */ '@/views/NotePdfPanel.vue')
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router
